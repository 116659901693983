<template>

  <div class="clearfix">
    <div class="card-img-overlay bg-dark" style="opacity: 0.7; z-index: -1"></div>
    <!--		<div style="position: fixed; top: 0; left: 0; right: 0; min-width: 100%; height: 60%; z-index: -1" class="show-sm">-->
    <!--			<img src="@/assets/images/bg-image.png" style="width: 100%; height: 100%; object-fit: cover"/>-->
    <!--		</div>-->
    <div id="famousSidebar" class="sidebar">
      <button class="btn closebtn" @click="closeNav()">
        <img src="@/assets/images/icon_menu_black.png">
      </button>
      <a @click="onClickLogin">Sign In</a>
      <a @click="onClickSupport">Support</a>
      <router-link :to="{path: '/terms', query: isEvent ? {event: 'hush'}: {}}">Terms of Service</router-link>
      <router-link :to="{path: '/privacy', query: isEvent ? {event: 'hush'}: {}}">Privacy Policy</router-link>
    </div>
    <div class="container-fluid" style="height: 100vh">
      <div class="home-top-section">
        <div class="row">
          <div class="col-lg-4 col-md-3 col-6">
            <button class="btn mt-3" @click="openNav()"><img src="@/assets/images/icon_menu.png" class="cart"></button>
          </div>
          <div class="col-lg-4 col-md-6 col-6 text-center mt-3 d-none d-md-flex">
            <img src="@/assets/images/logo.png" alt="Famous Jerk" class="img-fluid m-auto"/>
          </div>
          <div class="col-lg-4 col-md-3 col-6 text-right mt-4">
            <button class="btn button btn-signin mr-2" type="button" @click="onClickLogin"
                    v-if="!get(state, 'currentUser.id')">
              <span>SIGN IN</span>
            </button>
            <a @click="$router.push({path: '/cart', query: isEvent ? {event: 'hush'} : {}})" class="mr-2 ml-auto"
               v-if="get(state, 'currentUser.id') && get(state, 'currentCart.items')" ref="cartRef">
              <img src="@/assets/images/icon_cart.png" alt="" class="cart img-fluid"/>
              <div class="count" style="right: 15px;">
                {{getCount()}}
              </div>
            </a>
          </div>
        </div>
        <div class="d-flex d-md-none text-center pt-4 margin-mobile">
          <img src="@/assets/images/logo.png" alt="Famous Jerk" class="img-m-logo m-auto"/>
        </div>
        <div class="text-center text-white home-title" style="z-index: 1000">
          <div class="font-weight-bold" style="z-index: 1;">The Islands... Delivered<br/>To Your
            Doorsteps.
          </div>
        </div>
        <div class="px-3">
          <div class="row">
            <div class="col-lg-3 col-md-2 col-sm-12"></div>
            <div class="col-lg-6 col-md-8 col-sm-12 bg-white location">
              <div class="search-bar">
                <div class="d-flex w-100 pr-4" style="flex-direction: row;">
                  <img src="@/assets/images/icon_cursor.png" class="cursor">
                  <input class="location-text pr-5" placeholder="Enter delivery address" v-model="address"
                         style="width: 100%;"/>
                </div>
                <button class="btn button d-none d-md-block" @click="handleOffer"><span>DELIVER</span></button>
                <button class="btn button d-block d-md-none px-3" @click="handleOffer"><img
                  src="@/assets/images/icon_arrow_right.png" style="width: 20px;"/></button>
              </div>
              <div class="list-view" v-if="isOpen">
                <a class="address-item" v-for="(place, index) in state.google.placeResults" :key="index"
                   @click="onClickAddress(index)">{{place.formatted_address}}</a>
              </div>
              <div class="text-center mt-3 description-mobile" style="">Currently serving Los
                Angeles County
              </div>
            </div>
            <div class="col-lg-3 col-md-2 col-sm-12"></div>
          </div>
        </div>
        <div class="p-4 d-block d-md-none"></div>
        <div class="d-none d-md-flex" style="flex-direction: row; position:absolute; bottom: 10px; right: 10px;">
          <button class="btn" style="margin: 0; padding: 0; margin-right: 7px;">
            <a
              href="https://apps.apple.com/us/app/famous-jerk/id1543167871"
              target="_blank">
              <img src="@/assets/images/btn_apple.png" alt="" style="width: 100%;">
            </a>
          </button>
          <button class="btn" style="margin: 0; padding: 0;">
            <a
              href="https://play.google.com/store/apps/details?id=com.famous.jerk&hl=es_US&gl=US"
              target="_blank">
              <img src="@/assets/images/btn_google.png" alt="" class="img-fluid">
            </a>
          </button>
        </div>
      </div>
      <div class="d-block d-md-none position-relative bg-white">
        <div class="py-3 border-bottom">
          <div class="px-3">
            <div class="d-flex align-items-center">
              <div class="home-item-title w-75" style="text-transform: uppercase;">
                Get the best App experience
              </div>
              <div class="text-right w-100">
                <a
                  href="https://apps.apple.com/us/app/famous-jerk/id1543167871"
                  target="_blank"
                  class="margin: 5px; padding: 0"
                >
                  <img src="@/assets/images/btn_apple.png" alt="" class="img-fluid home-app-logo" style="width: 45%;">
                </a><br/>
                <a
                  href="https://play.google.com/store/apps/details?id=com.famous.jerk&hl=es_US&gl=US"
                  target="_blank"
                  class="margin: 5px; padding: 0"
                >
                  <img src="@/assets/images/btn_google.png" alt="" class="img-fluid home-app-logo"
                       style="width: 45%; margin-top: 5px;">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-bottom-section">
        <div class="font-weight-bold section-title home-product">Famous Jerk Dishes</div>
        <div class="row home-product">
          <a class="col-sm-12 col-md-6 col-lg-4 mt-5 product-item" v-for="(product, index) in products" :key="index"
             @click="$router.push({path: '/product', query: isEvent ? {id: get(product, 'id'), event: 'hush'}: {id: get(product, 'id')}})">
            <!-- <img :src="product.images[0].url" style="width: 100%;"> -->
            <img :src="get(product, 'images[0].url')" style="width: 100%;">
            <div class="mt-3 home-item-title home-item-title-lg">{{get(product, 'name')}}</div>
            <div class="home-item-desc">Delivered between 11AM-1PM, next day+
            </div>
            <div class="free-badge">FREE DELIVERY</div>
          </a>
        </div>
        <div style="border: 1px solid #bcbcbc; margin: 60px 70px 0 60px;" class="hidden-sm"></div>
        <div class="home-footer row">
          <div class="col-xl-6 col-lg-12">
            <div class="row d-flex" style="padding: 0 !important;">
              <div class="col-xl-2 col-lg-12" style="padding: 0 !important;">
                <img src="@/assets/images/icon_bottom_red_logo.png" class="img-fluid"/>
              </div>
              <div class="col-xl-9 col-lg-12 d-flex flex-column justify-content-center home-bottom-padding"
                   style="padding: 0 !important;">
                <div class="d-flex flex-row align-items-center">
                  <a><img src="@/assets/images/icon_instagram.png" class="mr-2"></a>
                  <a><img src="@/assets/images/icon_facebook.png" class="ml-2 mr-2"></a>
                  <a><img src="@/assets/images/icon_twitter.png" class="ml-2 mr-2"></a>
                </div>
                <div style="display: flex; flex-direction: row; margin-top: 7px;">
                  <router-link :to="{path: '/privacy', query: isEvent ? {event: 'hush'}: {}}" class="text1">Privacy
                    Policy
                  </router-link>
                  <span class="text1 ml-1 mr-1"> • </span>
                  <router-link :to="{path: '/terms', query: isEvent ? {event: 'hush'}: {}}" class="text1">Terms of
                    Service
                  </router-link>
                  <span class="text1 ml-1 mr-1"> • </span>
                  <a class="text1" @click="onClickSupport">Support</a>
                </div>
                <div class="text1">2021 Famous Jerk. All Rights Reserved.</div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-12 d-flex justify-content-end home-bottom-padding" style="padding: 0 !important;">
            <a
              href="https://apps.apple.com/us/app/famous-jerk/id1543167871"
              target="_blank"
              style="text-align: right"
              class="mr-2"
            >
              <img src="@/assets/images/btn_apple.png" alt="" class="img-fluid" style="width: 80%"
              /></a>
            <a
              href="https://play.google.com/store/apps/details?id=com.famous.jerk&hl=es_US&gl=US"
              target="_blank"
            >
              <img src="@/assets/images/btn_google.png" alt="" class="img-fluid" style="width: 80%"
              /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery'
  import {reactive, toRefs} from '@vue/composition-api'
  import {get} from 'lodash';
  import {json} from "overmind";
  import {Settings} from '../../settings';
  //
  export default {
    setup(props, {root}) {
      const data = reactive({
        address: null,
        isOpen: false,
        site: null,
        products: [],
        get,
        isModal: false,
        intervalAddress: null,
      });

      const handleOffer = async () => {
        await localStorage.setItem('famous_jerk_offerAddress', JSON.stringify(data.site));
        const loader = root.$loading.show();
        try {
          await root.$router.push({
            path: '/product-offer',
            query: root.$route.query?.event === 'hush' ? {event: 'hush'} : {}
          })
        } catch (e) {
          console.log(e.message);
        } finally {
          loader.hide();
        }
      };

      const searchGooglePlaces = async (val) => {
        try {
          await root.actions.google.searchGooglePlaces({keyword: val});
          data.isOpen = true;
        } catch (e) {
          console.log(e)
        }
      };

      const onClickAddress = (index) => {
        data.site = json(root.state.google.placeResults[index]);
        data.address = data.site.formatted_address;
        setTimeout(() => {
          data.isOpen = false;
        }, 1500)
      };

      const getSites = async () => {
        const loader = root.$loading.show();
        try {
          await root.actions.site.getSites({where: {groups_some: {name: 'kitchen'}, id: Settings.siteId}});
          await root.actions.product.getAddOns();
          // await root.actions.product.getProducts({ first: 50 });
          // root.state.currentUser?.id && root.actions.notification.getNotifications({ userId: root.state.currentUser?.id, where: { user: { id: root.state.currentUser?.id } }, first: root.state.notification.notificationPerPage, skip: (root.state.notification.activePage - 1) * root.state.notification.notificationPerPage });
          data.products = Object.values(json(root.state.site.sites))[0]?.products;
          // if (root.state.currentUser?.id) {
          //   root.$router.push('/product-offer');
          // }
        } catch (e) {
          console.log(e)
        } finally {
          loader.hide();
        }
      };

      const getOfferAddress = async () => {
        const item = JSON.parse(await localStorage.getItem('famous_jerk_offerAddress'));
        if (item?.formatted_address) {
          data.address = item?.formatted_address;
          data.site = item;
          setTimeout(() => data.isOpen = false, 1500)
        } else {
          console.log('======')
          navigator.geolocation.getCurrentPosition(async (res) => {
            await root.actions.user.getPlaceFromCoordinates({
              gps: {
                lat: res.coords.latitude,
                lon: res.coords.longitude
              }
            });
            data.address = json(root.state.user?.currentLocation?.formatted_address);
            data.site = json(root.state.user?.currentLocation);
            await localStorage.setItem('famous_jerk_offerAddress', JSON.stringify(data.site));
            setTimeout(() => data.isOpen = false, 1500)
          }, (error) => {
            console.log(error, '======')
            navigator.geolocation.watchPosition((res) => {
              console.log(res, 'res')
            })
          });
        }
      };

      const openNav = () => {
        document.getElementById("famousSidebar").style.left = "0";
      };
      const closeNav = () => {
        document.getElementById("famousSidebar").style.left = "-350px";
      };

      const getCount = () => {
        let count = 0;
        root.state.currentCart?.items?.map(i => count += i.quantity);
        return count;
      };

      const onClickSupport = () => {
        if (data.isModal) {
          $('#supportModal').modal('show');
        } else {
          root.$router.push('/support');
        }
      };

      const onClickLogin = () => {
        if (data.isModal) {
          $('#loginModal').modal('show');
        } else {
          root.$router.push('/login')
        }
      };

      return {
        handleOffer,
        searchGooglePlaces,
        onClickAddress,
        getSites,
        getOfferAddress,
        openNav,
        closeNav,
        getCount,
        onClickSupport,
        onClickLogin,
        ...toRefs(data)
      }
    },
    created() {
      $('body').addClass('home');
      this.getSites();
      this.getOfferAddress();
      // if (!this.address && !this.site) {
      //   this.intervalAddress = setInterval(() => this.getOfferAddress(), 10000)
      // }
    },
    mounted() {
      this.isModal = Settings.flow === 'modal'
    },
    destroyed() {
      $('body').removeClass('home');
      this.getOfferAddress();
    },
    computed: {
      isEvent() {
        return this.$route.query.event === 'hush';
      },
    },
    watch: {
      address: async function (val) {
        // if (val.length > 0) {
        //   clearInterval(this.intervalAddress);
        // }
        await this.searchGooglePlaces(val);
      },
      site: function () {
        this.isOpen = false;
      }
    }
  }
</script>

<style lang="css" scoped>

  .text1 {
    font-size: 13px;
    line-height: 16px;
  }

  .home-top-section {
    height: 100vh;
  }

  .btn-signin {
    position: static;
  }

  .img-m-logo {
    max-width: 180px;
  }

  .section-title {
    font-size: 35px;
    line-height: 43px;
  }

  .home-item-title {
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
  }

  .sidebar {
    height: 100%;
    width: 350px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: -350px;
    background-color: #F2F2F2;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 125px;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .sidebar a {
    text-decoration: none;
    font-size: 30px;
    font-weight: 500;
    line-height: 70px;
    color: #000000;
    display: block;
    transition: 0.3s;
  }

  .sidebar a:hover {
    color: #b2b2b2;
  }

  .sidebar .closebtn {
    position: absolute;
    top: 15px;
    left: -35px;
    margin-left: 50px;
  }

  .show-sm {
    display: none;
  }

  @media screen and (max-height: 450px) {
    .sidebar {
      padding-top: 15px;
    }

    .sidebar a {
      font-size: 18px;
    }
  }

  text1 {
    font-size: 12px;
    line-height: 15px;
  }

  .list-view {
    width: 100%;
    position: absolute;
    background-color: white;
    max-height: 400px;
    left: 0;
    top: 60px;
    border-radius: 5px;
    overflow-y: scroll;
    z-index: 30;
    border: 1px solid gray;
  }

  .address-item {
    display: flex;
    align-items: center;
    padding: 15px 18px;
    border-bottom-width: 1px;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-color: #B4B4B4;
    border-style: solid;
    font-weight: 300;
    font-size: 12px;
    text-transform: uppercase;
  }

  input.location-text {
    border: none;
    outline-width: 0;
    width: 100%;
  }

  .home-title {
    margin-top: 150px;
    font-size: 50px;
    line-height: 1.2;
  }

  .search-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .home-bottom-section {
    background-color: white;
    padding: 60px 20px 20px 20px;
  }

  .home-footer {
    border: 1px solid #dadada;
    border-left-width: 0px;
    border-right-width: 0;
    border-bottom-width: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 300px 10px 0 10px;
    padding: 32px 0 10px 0;
  }

  .home-product {
    padding: 0 70px;
  }

  .home-app-logo {
    width: 100%
  }

  /* */
  .description-mobile {
    font-size: 15px;
    line-height: 18px;
    color: #f5f5f5
  }

  .home-item-desc {
    font-size: 15px;
    line-height: 18px
  }

  @media (max-width: 1024px) {
    .show-sm {
      display: block;
    }

    .home-item-desc {
      font-size: 13px;
      line-height: 18px
    }

    .description-mobile {
      font-size: 12px;
      line-height: 15px;
      color: #f5f5f5
    }

    .hidden-sm {
      display: none;
    }

    .home-app-logo {
      width: 80%
    }

    .count {
      margin-top: -32px;
      font-size: 10px;
      margin-left: 20px;
      width: 15px;
      height: 15px;
    }

    .cart {
      width: 30px;
    }

    .home-product {
      padding: 0 0px;
      text-align: center;
    }

    .home-title {
      margin-top: 100px;
      font-size: 2.5rem;
    }

    .home-footer {
      margin-top: 150px;
    }

    .margin-mobile {
      padding-top: 10px !important;
    }
  }

  @media (max-width: 767px) {
    .home-top-section {
      height: auto;
    }

    .home-title {
      margin-top: 30px;
      font-size: 1.5rem;
    }

    .home-bottom-section {
      padding: 60px 30px 20px;
    }

    .home-footer {
      margin: 75px 0 0 0;
    }

    .section-title {
      font-size: 24px;
      line-height: 1.4;
    }

    .home-item-title {
      font-size: 14px;
      font-weight: bold;
      line-height: 17px;
      text-transform: uppercase;
    }

    .home-item-title-lg {
      font-size: 16px;
    }

    .home-bottom-padding {
      margin-top: 20px;
    }
  }
</style>
